<template>
  <div class="form-adicionar-content">
    <h5>Adicione conteúdo novo</h5>

    <form action="">
      <Select v-model="tipo" :options="options"></Select>
      <Input
        :error="$v.form.nome.$error"
        v-model="form.nome"
        :label="this.tipo === 'filme' ? 'Nome do filme' : 'Nome da série'"
      ></Input>
      <Input
        :error="$v.form.ano_lancamento.$error"
        v-model="form.ano_lancamento"
        label="Ano de lançamento"
      ></Input>
      <Input
        :error="$v.form.link_youtube.$error"
        v-model="form.link_youtube"
        label="Link do trailer no youtube"
      ></Input>
      <input @change="previewFiles" type="file" id="file" />
      <label for="file" class="ouline-person-button">
        Subir capa do filme
      </label>
      <ul class="list-file">
        <li v-for="(file, index) of form.capas" :key="index">
          {{ file.name }}
        </li>
      </ul>
      <Select :options="tags" :onChange="selectTags"></Select>
      <ul class="tag-wrapper">
        <Tag v-for="(tag, index) of form.tags" :key="index" :label="tag"> </Tag>
      </ul>
      <Input
        :error="$v.form.duracao.$error"
        v-model="form.duracao"
        :label="tipo === 'filme' ? 'Duração' : 'Temporadas'"
      ></Input>
      <Input
        :error="$v.form.direcao.$error"
        v-model="form.direcao"
        :label="this.tipo === 'filme' ? 'Direção' : 'Criador'"
      ></Input>
      <TextArea
        :maxlength="255"
        v-model="form.comentario"
        placeholder="O que você achou?"
      ></TextArea>
      <div>
        <h3>Qual é a sua nota ?</h3>
        <Rating
          @ratingSelected="form.nota = $event"
          v-model="form.nota"
        ></Rating>
      </div>
      <ButtonFilled
        :onClick="adicionarFilme"
        :isLoading="isLoading"
        :label="tipo === 'filme' ? 'Adicionar filme' : 'Adicionar série'"
      ></ButtonFilled>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import Select from "../common/Select/Select.vue";
import Input from "../common/Input/Input.vue";
//import ButtonOutline from "../components/common/Button/ButtonOutline.vue";
import ButtonFilled from "../common/Button/ButtonFilled.vue";
import TextArea from "../common/TextArea/TextArea.vue";
import Rating from "../common/Rating/RatingGreen.vue";
import Tag from "../common/Tag/Tag.vue";
import Filmes from "../../services/filmes";
import Series from "../../services/series";
import Comentarios from "../../services/comentarios";
import Notas from "../../services/notas";
import Imagens from "../../services/imagens";

export default {
  components: {
    Select,
    Input,
    //ButtonOutline,
    TextArea,
    Rating,
    ButtonFilled,
    Tag,
  },
  data() {
    return {
      isLoading: false,
      tipo: "filme",
      form: {
        nome: "",
        ano_lancamento: "",
        link_youtube: "",
        tags: [],
        duracao: "",
        direcao: "",
        comentario: "",
        nota: 0,
        capas: [],
      },
      options: [
        { text: "Filme", value: "filme", selected: "selected" },
        { text: "Série", value: "serie" },
      ],
      tags: [
        { text: "Tags", value: "", disabled: "disabled", hidden: "hidden" },
        { text: "Ação", value: "Ação" },
        { text: "Romance", value: "Romance" },
        { text: "Terror", value: "Terror" },
        { text: "Comédia", value: "Comédia" },
        { text: "Animação", value: "Animação" },
      ],
    };
  },
  validations: {
    form: {
      nome: { required },
      ano_lancamento: { required },
      link_youtube: { required },
      duracao: { required },
      direcao: { required },
      comentario: { required },
      tags: { required },
    },
  },
  computed: {},
  methods: {
    adicionarFilme() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("campos inválidos");
        console.log(this.$v);
        return;
      }

      this.isLoading = true;

      if (this.tipo === "serie") {
        this.form.temporadas = this.form.duracao;
        this.form.criador = this.form.direcao;
      }

      console.log("1");

      this.tipo === "filme"
        ? Filmes.create(this.form)
            .then((response) => {
              this.$toast.success(response.mensagem);
              this.createRatingAndComment("filmes", response.dados._id);
              this.$toast.success(this.tipo + " cadastrado com sucesso", {
                timeout: 2000,
              });
            })
            .catch((err) => {
              if (err.response.status === 401)
                this.$toast.error("Não foi possível cadastrar o filme, verifique as informações", {
                  timeout: 2000,
                });
              else
                this.$toast.error("Não foi possível cadastrar o filme, verifique as informações", {
                  timeout: 2000,
                });
              this.isLoading = false;
            })
        : Series.create(this.form)
            .then((response) => {
              this.$toast.success(response.mensagem);
              this.createRatingAndComment("series", response.dados._id);
              this.$toast.success(this.tipo + " cadastrado com sucesso", {
                timeout: 2000,
              });
            })
            .catch((err) => {
              if (err.response.status === 401)
                this.$toast.error("Não foi possível cadastrar a série, verifique as informações", {
                  timeout: 2000,
                });
              else
                this.$toast.error("Não foi possível cadastrar a série, verifique as informações", {
                  timeout: 2000,
                });
              this.isLoading = false;
            });

      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
    previewFiles(e) {
      console.log(e.target.files);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      Imagens.create(formData).then((response) => {
        if (!this.form.capas) this.form.capas = [];

        this.form.capas.push(response.dados.caminho);
      });
    },
    selectTags(e) {
      this.form.tags = [...this.form.tags, e.target.value];
      console.log(this.form.tags);
    },
    createRatingAndComment(colecao, id) {
      const user = JSON.parse(localStorage.getItem("auth.user"));

      Comentarios.create({
        comentario: this.form.comentario,
        colecao,
        filme_serie: id,
        usuario: user.id,
      }).catch(() => {});
      Notas.create({
        nota: this.form.nota,
        colecao,
        filme_serie: id,
        usuario: user.id,
      })
        .then(() => {
          this.$emit("onCreate");
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.form-adicionar-content {
  padding: 24px;
}

.form-adicionar-content h5 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;

  color: #009987;
  margin-bottom: 32px;
}

.form-adicionar-content .input-wrapper,
.form-adicionar-content .select {
  margin-bottom: 16px;
}

.form-adicionar-content .button {
  margin-bottom: 24px;
}

.form-adicionar-content .rating-container {
  margin-top: 19px;
  margin-bottom: 95px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.ouline-person-button {
  background: #fff;
  border-radius: 40px;
  border: 2px solid #ff7fa8;
  width: 100%;
  height: 64px;
  font-size: 16px;
  color: #ff7fa8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.form-adicionar-content .input,
.form-adicionar-content .select,
.form-adicionar-content .text-area {
  color: #004d44 !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.list-file {
  margin-top: 8px;
  margin-bottom: 14px;
  padding-left: 40px;
  font-size: 14px;
  line-height: 22px;
}

.list-file li {
  list-style-type: disc !important;
}

.tag-wrapper {
  display: flex;
  margin-bottom: 16px;
}

.tag-wrapper li {
  margin-right: 8px;
}
</style>