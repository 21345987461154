<template>
  <textarea 
    v-model="inputVal" 
    :placeholder="placeholder" 
    class="text-area" 
    name="" 
    id="" 
    cols="30" 
    rows="10"
    :maxlength="maxlength"
  ></textarea>
</template>

<script>
export default {
  props: ['placeholder', 'value', 'maxlength'],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.text-area {
  width: 100%;
  background: #ececec;
  border-radius: 16px;
  border: none;
  padding: 19px 24px;
  color: #8d8d8d;
  letter-spacing: 0.2px;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}
</style>