<template>
  <div class="input-wrapper">
      <Input :keyup="search" v-model="busca" label="O que você procura ?"></Input>
      
      <button @click="search">
        <img src="@/assets/icons/search-icon.svg" alt="" />
      </button>

    </div>
</template>

<script>
import Input from "../Input/Input.vue";

export default {
    components: {
        Input
    },
    data() {
        return {
            busca: ''
        }
    },
    methods: {
        search() {
            this.$router.push({ path: 'busca', query: { termo: this.busca }})
            this.busca = ''
        }
    }
}
</script>

<style>

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
}


.input-wrapper button {
  position: absolute;
  right: 3%;
  top: 25%;
  cursor: pointer;
}

</style>