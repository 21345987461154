<template>
   <nav class="nav">
       <router-link to="/">
        <img src="@/assets/images/logo-mini.png" alt="" />
       </router-link>
     <MenuMob></MenuMob>
    </nav>
</template>

<script>
import MenuMob from '../Menu/MenuMob.vue'

export default {
    components: {
        MenuMob
    }

}
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.nav img {
  width: 71px;
  height: 66px;
}

@media screen and (min-width: 700px){

  .nav {
    display: none;
  }
}

</style>