<template>
  <button @click.prevent="onClick" class="button" :disabled="disabled">
      <div v-if="isLoading">
          <div class="loading"></div>
      </div>
      <div v-else>
        <img src="@/assets/icons/Add-icon.svg" alt="">
      </div>
  </button>
</template>

<script>
export default {
    props: ['isLoading', 'onClick', 'disabled']
}
</script>

<style scoped>
    @keyframes loading {
        from {
            transform: rotate(0def);
        }
        to {
            transform:  rotate(360deg);
        }
    }

    .button {
        background: #FF7FA8;
        border-radius: 40px;
        border: none;
        width: 54px;
        height: 54px;
        font-size: 16px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 107px;
        right: 31px;
    }

    .button:focus {
        box-shadow: 3px 3px 3px #F2CBD7;
    }

    .loading {
        border: 2px solid #F7F7FC;
        border-bottom: 2px solid transparent;
        width: 20px;
        height: 20px;
        animation: loading 1s linear infinite;
        border-radius: 50%;
    }
    
    .button:disabled {
        background: #F2CBD7;
    }

    @media screen and (min-width: 700px) {
        .button {
            right: 15%;
        }
    }
</style>