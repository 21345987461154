import api from '../plugins/axios'

const routePath = "filmes"

export default {
    async findAll(){
        return await api.get(routePath)
    },
    async findOne(id){
        return await api.get(`${routePath}/${id}`)
    },
    async create(filme){
        return await api.post(routePath,filme)
    }
}