<template>
  <div class="menu-container">
    <router-link class="link" to="/">
      <img class="img" src="@/assets/images/logo-mini.png" alt="" />
    </router-link>
    <ul>
      <li>
        <router-link :class="{ active: getUrl === '/' }" to="/">
          <img src="@/assets/icons/home-icon.svg" alt="" /> <span>Home</span>
        </router-link>
      </li>
      <li>
        <router-link :class="{ active: getUrl === '/filmes' }" to="/filmes">
          <img src="@/assets/icons/filmes-icon.svg" alt="" />
          <span>Filmes</span>
        </router-link>
      </li>
      <li>
        <router-link :class="{ active: getUrl === '/series' }" to="/series">
          <img src="@/assets/icons/series-icon.svg" alt="" />
          <span>Séries</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link :class="{ active: getUrl === '/busca' }">
          <img src="@/assets/icons/busca-icon.svg" alt="" /> <span>Busca</span>
        </router-link>
      </li> -->
    </ul>

    <div class="logged">      
      <p class="logged-user">{{$store.getters.getUser.nome}}</p>
      <Links to="/logout" label="Sair"></Links>
    </div>
  </div>
</template>

<script>
import Links from '../Links/Links.vue'

export default {
  components: {
    Links
  },
  data() {
    return {};
  },
  computed: {
    getUrl: function () {
      return this.$route.path;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.menu-container {
  width: 100%;
  background: #009987;
  height: 75px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 15%;
}

.link, .logged {
  display: none;
}

.logged-user {
  text-transform: capitalize;
  margin-bottom: 5px;
}

.menu-container ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-container ul li {
  background: transparent;
  border: none;
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.menu-container ul li a {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-container ul li a.active {
  background: rgba(0, 0, 0, 0.3);
}

.menu-container span {
  display: none;
}

@media screen and (min-width: 700px) {
  .menu-container {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 0 20%;
  }

  .link {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logged {
    display: block;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  

  .img {
    max-height: 80%;
  }
  .menu-container ul {
    justify-self: center;
    width: 60%;
  }

  .menu-container span {
    color: #fff;
    display: block;
  }

  .menu-container ul li {
    background: transparent;
    border: none;
    width: 100px;
    height: 50px;
    border-radius: 0;
  }

  .menu-container ul li a {
    width: 100%;
    height: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .menu-container ul li a.active {
    background: transparent;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  }
}
</style>