import api from '../plugins/axios'

const routePath = "series"

export default {
    async findAll(){
        return await api.get(routePath)
    },
    async findOne(id){
        return await api.get(`${routePath}/${id}`)
    },
    async create(serie){
        return await api.post(routePath,serie)
    }
}