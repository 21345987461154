<template>
  <div class="rating-container">
    <StarRating
      border-color="#009987"
      inactive-color="#fff"
      active-color="#009987"
      :rounded-corners="true"
      :border-width="3"
      :show-rating="false"
      :star-size="40"
      v-model="rating"
      @rating-selected="setRating"
    />
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  props: ['value'],
  components: {
    StarRating,
  },
  data(){
    return{
      rating: this.value
    }
  },
  methods: {
    setRating(){
      console.log(this.rating)
      this.$emit('ratingSelected', this.rating)
    }
  },
};
</script>

<style>
.vue-star-rating-rating-text {
  display: none;
}
.rating-container {
  display: flex;
  justify-content: center;
}
</style>