<template>
  <li class="tag">
    <span>
      {{label}}
    </span>
  </li>
</template>

<script>
export default {
    props:['label']
};
</script>

<style>
.tag {
  display: flex;
  align-items: center;
  padding: 0px 8px;

  width: fit-content;

  background: #009987;
  border-radius: 48px;
}

.tag span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  letter-spacing: 0.2px;

  color: #e6fdfa;
}
</style>