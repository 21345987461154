import api from '../plugins/axios'

const routePath = "comentarios"

export default {
    async findAll(){
        return await api.get(routePath)
    },
    async findOne(id){
        return await api.get(`${routePath}/${id}`)
    },
    async findByFilmeSerie(id){
        let uri = encodeURI(`procurar[termo]=filme_serie&procurar[valor]=${id}&procurar[tipo]=objectId`)
        return await api.get(`${routePath}/?${uri}`)
    },
    async create(comentario){
        return await api.post(routePath,comentario)
    }
}