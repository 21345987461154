<template>
  <nav>
    <button @click="show = true">
      <img src="@/assets/icons/menu-icon.svg" alt="" />
    </button>
    <div :class="{ menu: true, show: show }">
      <div class="wrapper">
        <button @click="show = false">
          <img src="@/assets/icons/Close.svg" alt="">
        </button>
        <div class="display">
          <div>
            <h3>{{$store.getters.getUser.apelido}}</h3>
            <p>{{$store.getters.getUser.nome}}</p>
            <p>{{$store.getters.getUser.telefone}}</p>
            <p>{{$store.getters.getUser.data_nascimento}}</p>
            <p>{{$store.getters.getUser.cidade}}</p>
            <p>{{$store.getters.getUser.email}}</p>
          </div>
        </div>
        <div class="content">
          <router-link to="/logout">Sair da conta</router-link>

        </div>
        
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.nav button {
  border: none;
  background: transparent;
  width: 24px;
  height: 16px;
}

.nav button img {
  width: 100%;
  height: 100%;
}
.menu {
  position: fixed;
  background: #004d44;
  top: 0;
  right: -100%;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  transition: 0.6s;
}

.menu.show {
  right: 0;
}

.menu .wrapper {
  height: 100vh;
  background: #fff;
  margin-left: 24px;
  margin-right: 40px;
  width: 90%;
}

.wrapper .display {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: 40px;
}

.wrapper .content {
  margin-left: 40px;
  padding-top: 325px;
}

.wrapper .content a {
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.menu button {
  position: absolute;
  right: 10%;
  margin-top: 70px;
}
</style>